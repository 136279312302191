import { config } from 'src/config';
import { Chart } from '../Chart';
import './BottomHero.css';

export const BottomHero = () => {
    return (
        <div className="container">
            <Chart />
            <div className="footer">
                <div className="footerLinks">
                    <a href="https://github.com/stellar-shroomz" className="footerLink">GITHUB</a>
                    <a href="https://reddit.com/r/stellarshroomz" className="footerLink">REDDIT</a>
                    <a href="https://twitter.com/StellarShroomz" className="footerLink">TWITTER</a>
                    <a href={`https://stellar.expert/explorer/public/asset/${config.ASSET_CODE}-${config.ASSET_ISSUER}`} className="footerLink">STELLAR.EXPERT</a>
                </div>
            </div>
        </div>
    )
}
