import "./ProgressBar.css";

export const ProgressBar = ({
    progress,
    background,
    fill,
    color,
    label
}: {
    progress: number;
    background: string;
    fill: string;
    color: string;
    label?: string;
}) => {
    return (
        <div className="progressbarContainer" style={{
            backgroundColor: background,
            border: `1px solid ${fill}`
        }}>
            <div className="progressbarLabel" style={{
                color: color,
                fontWeight: "bold",
                fontSize: "0.8rem"
            }}>{label}</div>
            <div className="progressbarProgress" style={{
                backgroundColor: fill,
                color: color,
                fontWeight: "bold",
                fontSize: "0.8rem",
                width: `${progress}%`
            }}>{progress}%</div>
        </div>
    )
}
