import "./Tokenomics.css";
import { QRCodeComponent } from "../QRCodeComponent";
import { Spacer } from "../Spacer";
import { Button } from "../Button";
import { config } from "src/config";

export const Tokenomics = () => {
    return (
        <div className="tokenomicsContainer">
            <div style={{
                overflow: "hidden",
            }}>
                <div>
                    <div className="tokenomicsSubtitle">Code</div>
                    <div className="tokenomicsTitle">{config.ASSET_CODE}</div>
                </div>
                <Spacer height={"2rem"} />
                <div>
                    <div className="tokenomicsSubtitle">Issuer</div>
                    <div className="tokenomicsTitle" style={{ overflow: "scroll" }}>{config.ASSET_ISSUER}</div>
                </div>
                <Spacer height={"2rem"} />
                <div>
                    <div className="tokenomicsSubtitle">Created</div>
                    <div className="tokenomicsTitle">02.05.2024</div>
                </div>
                <Spacer height={"2rem"} />
                <div>
                    <div className="tokenomicsSubtitle">Supply</div>
                    <div className="tokenomicsTitle">5,000,000,000</div>
                </div>
            </div>
            <div className="qrComponent">
                <QRCodeComponent height={180} width={180} color="#d82655" />
                <p style={{
                    fontSize: "0.8rem",
                    fontWeight: "bold",
                    color: "#fff",
                    textAlign: "center"
                }}>Scan using <a href="https://stashapp.cloud" style={{ color: "#fff" }}>StashApp</a> to add trust line</p>
                <Button 
                    style={{ width: "100%" }} 
                    link="https://stellar.expert/explorer/public/asset/SHROOMZ-GCAKKZCTJPDVMHPSKNRZIAGEJWZS6LQ5NGZIGFN2LIQGC6S2EV5SMHRB" 
                    title="EXPLORER" 
                    color="#d82655" 
                    fontWeight="800"
                />
            </div>
        </div>
    );
}
