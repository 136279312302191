import { IconProps } from "./types";

export const SwapIcon = ({
    color,
    width,
    height,
}: IconProps) => (
    <svg width={width} height={height} viewBox="0 0 70 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
        fillRule="evenodd" 
            clipRule="evenodd" 
            d="M32.234 11.266C21.171 22.36 19.505 25.105 22.2 27.8C24.426 30.026 26.425 29.025 33.225 22.277L40 15.554V37.122C40 57.435 40.117 58.796 42.004 60.504C43.66 62.002 44.442 62.12 46.504 61.18L49 60.043V38.021C49 25.91 49.35 16 49.779 16C50.207 16 53.457 18.925 57 22.5C60.543 26.075 64.211 29 65.15 29C67.294 29 70 26.364 70 24.275C70 21.841 47.991 0 45.539 0C44.231 0 39.327 4.152 32.234 11.266ZM22.2 39.2C21.34 40.06 21 46.578 21 62.2C21 74.19 20.65 84 20.221 84C19.793 84 16.543 81.075 13 77.5C9.457 73.925 5.789 71 4.85 71C2.706 71 0 73.636 0 75.725C0 78.159 22.009 100 24.461 100C27.241 100 49 78.217 49 75.434C49 73.026 47.207 71 45.075 71C44.236 71 40.501 74.025 36.775 77.723L30 84.446V62.878C30 43.253 29.835 41.161 28.171 39.655C26.012 37.701 23.863 37.537 22.2 39.2Z"
            fill={color}
        />
    </svg>
)
