import { Asset, Horizon } from "@stellar/stellar-sdk";
import axios from "axios";
import { DateTime } from "luxon";

interface Order {
    amount: string;
    price: string;
    price_r: {
        n: number;
        d: number;
    };
}

interface OrderbookResponse {
    asks: Order[];
    bids: Order[];
    price?: number | string | undefined;

}

const client = new Horizon.Server("https://horizon.stellar.org");

export const queryOrderbook = async ({
    code,
    issuer
}: {
    code: string;
    issuer: string;
}): Promise<OrderbookResponse> => {
    try {
        const data = await client.orderbook(new Asset(code, issuer), Asset.native()).call()
        const price = await queryAssetPrice({ code, issuer, dest: "USD" })
        return {
            ...data,
            price
        }
    } catch (error) {
        console.warn("Error fetching data.");
        return {
            asks: [],
            bids: [],
            price: 0
        };
    }
}

export const queryAssetPrice = async ({
    code,
    issuer,
    dest
}: {
    code: string;
    issuer: string;
    dest: "USD" | "XLM";
}): Promise<number> => {
    try {
        if (dest === "USD") {
            const { records } = await client.strictSendPaths(new Asset(code, issuer), "1", [new Asset("USDC", "GA5ZSEJYB37JRC5AVCIA5MOP4RHTM335X2KGX3IHOJAPP5RE34K4KZVN")]).call();
            const [{ destination_amount }] = records;
            return parseFloat(destination_amount)
        }
        const { records } = await client.strictSendPaths(new Asset(code, issuer), "1", [Asset.native()]).call();
        const [{ destination_amount }] = records;
        return parseFloat(destination_amount)
    } catch (error) {
        console.warn("Error fetching asset price.");
        return 0;
    }
}

export const queryAssetPriceHistory = async ({
    code,
    issuer,
}: {
    code: string;
    issuer: string;
}): Promise<any> => {
    try {
        const { data } = await axios.get(`https://horizon.stellar.org/trade_aggregations?base_asset_type=native&counter_asset_type=credit_alphanum12&counter_asset_code=${code}&counter_asset_issuer=${issuer}&resolution=86400000&offset=3600000&order=desc`)

        if (data._embedded.records.length === 0) return null;
        return {
            labels: data._embedded.records.reverse().map((record: any) => DateTime.fromMillis(parseFloat(record.timestamp)).toFormat("dd.MM.y")),
            data: data._embedded.records.map((record: any) => parseFloat(record.close_r.d) / parseFloat(record.close_r.n)),
        }
    } catch (error) {
        console.warn("No pricee history.");
        return null;
    }
}


