import { Spacer } from "../Spacer";
import { ProgressBar } from "./ProgressBar";
import "./ProgressBar.css";

interface ProgressItem {
    label: string;
    progress: number;
}
interface ProgressBarListProps {
    list: ProgressItem[];
}

export const ProgressBarList = ({ 
    list 
}: ProgressBarListProps) => {
    return (
        <div className="listContainer">
            {list.map((item: ProgressItem) => (
                <div key={item.label}>
                    <ProgressBar label={item.label} color={"#fff"} progress={item.progress} background={"#0B1729"} fill={"#d82655"} />
                    <Spacer height={"1rem"} />
                </div>
            ))}
        </div>
    )
}
