import "./Orderbook.css";
import { useQueryContext } from "src/context/QueryContext";
import { calculateAmountPercentage, numberShortener, priceAmountMap } from "src/services";

const OrderbookBuys = ({ 
    data,
}: { 
    data: any;
}) => {
    const biggestAmount = Math.max(...data.map((item: any) => parseFloat(item.amount)));

    return (
        <div className="orderbookRowList">
            {data.map((item: any, index: number) => (
                <div key={index} className="orderbookLine">
                    <div className="orderbookLineProgressRight" style={{
                        width: `${calculateAmountPercentage(parseFloat(item.amount), biggestAmount, 200)}%`
                    }} />
                    <div
                        className="orderbookLineProgressTextRight"
                        style={{
                            width: "100%",
                            justifyContent: "space-between"
                        }}
                    >
                        <p style={{ marginLeft: "1rem" }}>{parseFloat(item.price).toFixed(7)}</p>
                        <p>{numberShortener(item.amount)}</p>
                    </div>
                </div>
            ))}
        </div>
    )
}
const OrderbookSells = ({ 
    data
}: { 
    data: any;
}) => {
    const biggestAmount = Math.max(...data.map((item: any) => parseFloat(item.amount)));
    
    return (
        <div className="orderbookRowList">
            {data.map((item: any, index: number) => (
                <div key={index} className="orderbookLine">
                    <div
                        className="orderbookLineProgressLeft"
                        style={{
                            width: `${calculateAmountPercentage(parseFloat(item.amount), biggestAmount, 200)}%`
                    }} />
                    <div
                        className="orderbookLineProgressTextLeft"
                        style={{
                            width: "100%",
                            justifyContent: "space-between"
                        }}
                    >
                        <p>{numberShortener(item.amount)}</p>
                        <p style={{ marginRight: "1rem" }}>{parseFloat(item.price).toFixed(7)}</p>
                    </div>
                </div>
            ))}
        </div>
    );
}
const OrderbookHeader = () => {
    return (
        <div className="orderbookHeader" style={{
            marginBottom: "1rem"
        }}>
            <div className="orderbookHeaderItem"><p className="headerText">AMOUNT</p></div>
            <div className="orderbookHeaderItem center"><p className="headerText">Price XLM</p></div>
            <div className="orderbookHeaderItem"><p className="headerText">AMOUNT</p></div>
        </div>
    );
};
export const Orderbook = () => {
    
    const { data, loading, error } = useQueryContext();
    
    if (!data?.orderbook) return null

    const { orderbook } = data;
    
    const mapAsks = priceAmountMap(orderbook.asks);
    const mapBids = priceAmountMap(orderbook.bids);

    if (loading) {
        return <p className="orderbookItemLeft">Loading...</p>
    }

    return (
        <div className="orderbookContainer">
            <OrderbookHeader />
            {error ? 
                <div className="orderbookHorizontal">
                    <p className="orderbookItemLeft" style={{ fontWeight: "600" }}>{error}</p>
                </div>
            :
                <div className="orderbookHorizontal">
                    {data.orderbook.bids && <OrderbookSells data={mapBids.slice(0, 10)} />}
                    {data.orderbook.asks && <OrderbookBuys data={mapAsks.slice(0, 10)} />}
                </div>
            }
        </div>
    )
}
