import { CSSProperties, useState } from "react";
import "./Button.css";

interface ButtonProps {
    title?: string;
    icon?: any;
    color?: string;
    hoverColor?: string;
    fontWeight?: string;
    link?: string;
    style?: CSSProperties;
}

export const Button = ({
    title,
    color,
    hoverColor,
    fontWeight,
    icon,
    link,
    style
}: ButtonProps) => {
    const [hover, isHovering] = useState<boolean>(false);

    return (
        <div className="buttonContainer"
            onMouseEnter={(e) => isHovering(true)} 
            onMouseLeave={(e) => isHovering(false)}  
            onClick={() => window.open(link, "_self")}     
            style={{
                ...style,
                borderColor: color,
                backgroundColor: hover ? color : "transparent",
            }}
            
        >
            {title && 
                <p className="buttonText" style={{
                    color: hover ? hoverColor : color,
                    fontWeight: fontWeight,
                    marginRight: icon ? "0.4rem" : "0"
                }}>{title}</p>
            }
            {icon && icon}
        </div>
    )
}
