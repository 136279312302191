export const SectionDivider = ({ color }: { color: string; }) => {
    return (
        <div className="dividerWrapper" style={{ backgroundColor: "transparent" }}>
            <svg viewBox="0 0 1920 123" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path 
                d="M0 4.99998L10.3 13C20.7 21 41.3 37 62 42.7C82.7 48.3 103.3 43.7 124 41.3C144.7 39 165.3 39 186 37.3C206.7 35.7 227.3 32.3 248 29.3C268.7 26.3 289.3 23.7 310 24C330.7 24.3 351.3 27.7 372 35C392.7 42.3 413.3 53.7 433.8 48C454.3 42.3 474.7 19.7 495.2 9.19999C515.7 -1.30001 536.3 0.299976 557 1.49998C577.7 2.69998 598.3 3.29999 619 8.69999C639.7 14 660.3 24 681 33.8C701.7 43.7 722.3 53.3 743 47.8C763.7 42.3 784.3 21.7 805 12.2C825.7 2.69999 846.3 4.29996 867 10.3C887.7 16.3 908.3 26.7 929 31.3C949.7 36 970.3 35 991 29.8C1011.7 24.7 1032.3 15.3 1053 18.7C1073.7 22 1094.3 38 1115 46C1135.7 54 1156.3 54 1177 45.7C1197.7 37.3 1218.3 20.7 1239 20.8C1259.7 21 1280.3 38 1301 37.2C1321.7 36.3 1342.3 17.7 1363 8.19999C1383.7 -1.30001 1404.3 -1.70002 1424.8 9.49998C1445.3 20.7 1465.7 43.3 1486.2 52.5C1506.7 61.7 1527.3 57.3 1548 46.3C1568.7 35.3 1589.3 17.7 1610 19C1630.7 20.3 1651.3 40.7 1672 48.2C1692.7 55.7 1713.3 50.3 1734 40.8C1754.7 31.3 1775.3 17.7 1796 16.3C1816.7 15 1837.3 26 1858 31.3C1878.7 36.7 1899.3 36.3 1909.7 36.2L1920 36V123H1909.7C1899.3 123 1878.7 123 1858 123C1837.3 123 1816.7 123 1796 123C1775.3 123 1754.7 123 1734 123C1713.3 123 1692.7 123 1672 123C1651.3 123 1630.7 123 1610 123C1589.3 123 1568.7 123 1548 123C1527.3 123 1506.7 123 1486.2 123C1465.7 123 1445.3 123 1424.8 123C1404.3 123 1383.7 123 1363 123C1342.3 123 1321.7 123 1301 123C1280.3 123 1259.7 123 1239 123C1218.3 123 1197.7 123 1177 123C1156.3 123 1135.7 123 1115 123C1094.3 123 1073.7 123 1053 123C1032.3 123 1011.7 123 991 123C970.3 123 949.7 123 929 123C908.3 123 887.7 123 867 123C846.3 123 825.7 123 805 123C784.3 123 763.7 123 743 123C722.3 123 701.7 123 681 123C660.3 123 639.7 123 619 123C598.3 123 577.7 123 557 123C536.3 123 515.7 123 495.2 123C474.7 123 454.3 123 433.8 123C413.3 123 392.7 123 372 123C351.3 123 330.7 123 310 123C289.3 123 268.7 123 248 123C227.3 123 206.7 123 186 123C165.3 123 144.7 123 124 123C103.3 123 82.7 123 62 123C41.3 123 20.7 123 10.3 123H0V4.99998Z" 
                fillRule="evenodd" clipRule="evenodd"
                fill={color}
            />
            </svg>        
        </div>
    )
}
