import { Divider } from './Divider';
import './HeroDivider.css';

export const HeroDivider = () => {
    return (
        <div className="dividerWrapper" style={{ paddingBottom: -20, width: "100vw"}}>
            <Divider />
        </div>
    )
}
