import "./Spacer.css";

export const Spacer = ({
    height,
    width
}: {
    height?: number | string;
    width?: number | string;
}) => {
    return (
        <div className="spacerContainer" style={{
            height: height,
            width: width
        }} />
    )
}
