import { useState } from "react";
import { IconProps } from "./types";

export const RedditIcon = ({
    color,
    width,
    height,
    hoverColor
}: IconProps) => {
    const [hover, isHovering] = useState<boolean>(false);

    return (
        <svg
            onMouseEnter={(e) => isHovering(true)}
            onMouseLeave={(e) => isHovering(false)}
            width={width} height={height} viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path 
                fillRule="evenodd" 
                clipRule="evenodd" 
                d="M33.1049 1.58299C11.4499 7.40599 -2.93906 29.215 0.732942 50.648C3.95194 69.444 18.5559 84.048 37.3519 87.267C65.6889 92.121 92.1209 65.689 87.2669 37.352C82.8719 11.694 57.5909 -5.00201 33.1049 1.58299ZM44.4029 25.884L42.7089 33.768L38.3509 34.365C35.9549 34.694 32.3079 35.807 30.2469 36.838C27.7659 38.08 25.2179 38.555 22.7049 38.242C19.9799 37.904 18.3599 38.27 16.9549 39.54C14.6319 41.643 14.3889 46.248 16.5089 48.007C17.3389 48.696 18.3089 51.077 18.6639 53.298C19.9349 61.246 29.0139 67.925 40.6589 69.475C46.9679 70.316 56.8339 68.022 62.2299 64.46C66.1009 61.905 69.9999 55.636 69.9999 51.968C69.9999 50.807 70.6749 49.182 71.4999 48.357C73.6129 46.245 73.3639 41.639 71.0229 39.52C69.5749 38.211 68.0979 37.885 65.5119 38.304C63.1709 38.684 60.5469 38.25 57.7389 37.018C55.4079 35.995 51.8129 34.847 49.7499 34.468C47.6879 34.088 45.9999 33.453 45.9999 33.055C45.9999 32.658 46.5209 29.737 47.1589 26.564L48.3169 20.795L52.4089 21.42C54.6589 21.763 57.2469 22.935 58.1599 24.022C61.7689 28.32 68.5819 24.825 65.9999 20C64.8919 17.93 59.6429 17.151 58.5639 18.896C58.2419 19.418 56.4429 19.428 54.2549 18.92C46.3969 17.095 46.2729 17.182 44.4029 25.884ZM30.6039 46.155C28.9739 48.482 29.8909 51.637 32.4869 52.633C34.7059 53.485 37.9999 51.113 37.9999 48.665C37.9999 43.996 33.2459 42.383 30.6039 46.155ZM51.5959 45.306C47.8459 48.048 51.8329 54.848 55.7499 52.393C57.9199 51.032 58.5539 47.373 56.9539 45.445C55.4679 43.654 53.9099 43.614 51.5959 45.306ZM32.9999 59.891C32.9999 61.185 40.5359 64 43.9999 64C47.4639 64 54.9999 61.185 54.9999 59.891C54.9999 58.673 54.4909 58.743 48.8839 60.735C44.2489 62.381 43.7509 62.381 39.1159 60.735C33.5089 58.743 32.9999 58.673 32.9999 59.891Z" 
                fill={hover ? hoverColor : color}
                style={{ transition: "fill 0.3s" }}
            />
        </svg>
    )
}
