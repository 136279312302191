import "./HorizontalScroll.css";

interface HorizontalScrollProps {
    children: any;
    style?: any;
}

export const HorizontalScroll = ({
    children,
    style
}: HorizontalScrollProps) => {
    return (
        <div className="horizontalScrollContainer" style={style}>
            {children}
        </div>
    )
}
