import './TopMenu.css';

interface MenuItem {
    label: string;
    url: string;
}

interface TopMenuProps {
    links: MenuItem[],
    logo: string;
}

export const TopMenu = ({
    links,
    logo
}: TopMenuProps) => {
    return (
        <div className="topMenu">
        </div>
    )
}
