import "./Chart.css";
import { Chart as ChartJS, Tooltip, Legend, LinearScale, LineElement, PointElement, Title, CategoryScale } from "chart.js";
import { Line } from "react-chartjs-2";
import { useChart } from "./useChart";
import { useQueryContext } from "src/context/QueryContext";
import { Spacer } from "../Spacer";


export const Chart = () => {
    const { 
        opertaions: { 
            getGradient
        }, 
        models
    } = useChart()

    const { data } = useQueryContext();
    ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
    
    if (!data?.history) return (
        <div className="error">
            <Spacer height={"2rem"} />
            <p className="topSubtitle" style={{ width: "100%" }}>No asset history yet.</p>
        </div>
    );

    const dataset = {
        labels: data?.history?.labels,
        datasets: [{
            data: data?.history?.data,
            borderColor: function(context: any) {
                const chart = context.chart;
                const { ctx, chartArea } = chart;
        
                if (!chartArea) return;
                return getGradient(ctx, chartArea);
              },
            tension: 0.4,
            borderWidth: 4,
        }],
        
    };
    
    return (
        <Line options={models.options} data={dataset} />
    );
}
