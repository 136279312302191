export const numberShortener = (num: number) => {
    const abbreviations = ['', 'K', 'M', 'B', 'T', 'Q', 'QQ', 'S', 'SS', 'O', 'N', 'D', 'UN', 'DD', 'TD', 'QD', 'QQD', 'SD', 'SSD', 'OD', 'ND', 'V'];
    let abbreviationIndex = 0;

    while (num >= 1000 && abbreviationIndex < abbreviations.length - 1) {
        num /= 1000;
        abbreviationIndex++;
    }

    let returnNum = parseFloat(`${num}`).toFixed(0);
    if (num < 0.9999999) {
        returnNum = parseFloat(`${num}`).toFixed(3);
    }

    return returnNum + abbreviations[abbreviationIndex];
}

export const calculateAmountPercentage = (amount: number, biggestAmount: number, parentWidth: number) => {
    const percentage = (amount / biggestAmount) * 100;
    const maxWidth = parentWidth - 20;
    return Math.min(percentage, maxWidth);
};

export const priceAmountMap = (data: any[]): { amount: string, price: string }[] => {
    const result: { [key: string]: number } = data.reduce((acc, item) => {
        const price = item.price;
        const amount = parseFloat(item.amount);
        acc[price] = (acc[price] || 0) + amount;
        return acc;
    }, {});

    return Object.keys(result).map(price => ({ amount: result[price].toString(), price }));
};
