import { useState } from "react";
import "./Converter.css";
import { SwapIcon } from "../Icons";
import { useQueryContext } from "src/context/QueryContext";

const ConverterInput = ({
    value,
    label,
    onChange
}: {
    value: string | number;
    label?: string;
    defaultValue?: number;
    onChange: (value: string) => void;
}) => {
    return (
        <div className="converterInput">
            <p className="label">{label}</p>
            <input 
                onChange={(e) => onChange(e.target.value)}
                value={value}
                className="input" 
                type="text"
                placeholder="Enter amount"
            />
        </div>
    )
}

const ConvertButton = () => {
    return (
        <div className="convertButton">
            <div className="button">
                <SwapIcon color="#0B1729" width="40" height="30" />
            </div>
        </div>
    )
}

const Spacer = () => (
    <>
        <div style={{ height: "1.5rem" }} />
        <div style={{ height: 0.1 }} className="spacerLine" />
        <div style={{ height: "1.5rem" }} />
    </>
)

export const Converter = () => {
    const { data } = useQueryContext();
    const price = data?.orderbook?.price || 0;
    const [base, setBase] = useState<string |number>(1);
    const [received, setReceived] = useState<string | number>(price);

    const handleBaseChange = (input: string) => {
        const value = parseFloat(input);

        if (isNaN(value * price)) return setReceived(price);

        setBase(input);
        setReceived(value * price);
    }

    const handleReceivedChange = (input: string) => {
        const value = parseFloat(input);
        
        if (isNaN(value / price)) return setBase(1);

        setBase(value / price);
        setReceived(value);
    }

    return (
        <div className="converterContainer">
            <ConverterInput onChange={handleBaseChange} value={base} label="SHROOMZ" />
            <Spacer />
            <ConverterInput onChange={handleReceivedChange} value={received} label="USD" />
            <ConvertButton />
        </div>
    )
}
