import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Helmet } from "react-helmet";
import { QueryContextProvider } from './context/QueryContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <QueryContextProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Stellar Shroomz - The brand new shrooms token on the block.</title>
          <meta name="description" content="Stellar Shroomz is a brand new DeFi token on the Stellar network. It's set to redefine the magic mushroom payment landscape. Inspired by the magic of mushrooms, Shroomz is a decentralized digital currency with a thriving community." />
          <link rel="canonical" href="https://shroomztoken.xyz" />
        </Helmet>
      <App />
    </QueryContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
