import './App.css';
import { Hero } from './components';
import { BottomHero } from './components/BottomHero';
import { Button } from './components/Button';
import Card from './components/Card/Card';
import { Converter } from './components/Converter';
import { HorizontalScroll } from './components/HorizontalScroll';
import { Orderbook } from './components/Orderbook';
import { ProgressBarList } from './components/ProgressBar';
import { Section } from './components/Section';
import { SectionDivider } from './components/SectionDivider/SectionDivider';
import { SectionTitle } from './components/SectionTitle';
import { Spacer } from './components/Spacer';
import { Title } from './components/Title';
import { Tokenomics } from './components/Tokenomics';
import { TopMenu } from './components/TopMenu';
import { useQueryContext } from './context/QueryContext';

function App() {
  const { data } = useQueryContext();

  const menu = [
    { label: 'Home', url: '/' },
    { label: 'About', url: '/about' },
    { label: 'Contact', url: '/contact' }
  ]

  const roadmap = [
    { step: "01", title: 'Launch', subtitle: 'Launch of Shroomz on the Stellar network', done: true },
    { step: "02", title: 'Tipbot', subtitle: 'Reddit tipbot integration', done: true },
    { step: "03", title: 'Liqudity', subtitle: 'Liquidty pool on Stellar DEX', done: true },
    { step: "04", title: 'Raffles', subtitle: 'Automated raffle systems', done: true },
    { step: "05", title: 'Partners', subtitle: 'Partnerships with other DeFi projects', done: false },
  ]

  const tokenomics = [
    { label: "Total circulation", progress: 100, color: "#d82655"},
    { label: "Liquidity pools / staking rewards", progress: 35, color: "#d82655"},
    { label: "Tipping & giveaways", progress: 20, color: "#d82655" },
    { label: "Marketing (activities)", progress: 15, color: "#d82655" },
    { label: "Development & liquidity", progress: 10, color: "#d82655" }
  ]

  return (
    <>
      <TopMenu logo="logo.svg" links={menu} />
      <Hero 
        cta="🚀 Introducing Shroomz - Stellar Shroomz, Revolutionary DeFi Token 🍄"
        title="Stellar Shroomz"
        subtitle="Embark on a journey with Shroomz, a revolutionary DeFi token on the Stellar network that's set to redefine the magic mushroom payment landscape. Inspired by the magic of mushrooms, Shroomz is a decentralized digital currency with a thriving community."
      />
      <Section style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}>
        <SectionTitle title="Price" style={{ width: "80vw" }} />
        <Converter />
        <Spacer height={"4rem"} />
        <SectionTitle title="Order book" style={{ width: "80vw" }} />
        <Orderbook />
        <Spacer height={"2rem"} />
        <div className="buttonGroup">
          <Button title="BUY ON STASHAPP" hoverColor="#0B1729" color="#d82655" fontWeight="800" link="https://stashapp.cloud" />
          <Button title="BUY ON STELLARX" hoverColor="#0B1729" color="#d82655" fontWeight="800" link="https://stellarx.com" />
          <Button title="LISTED ON RCCMARKETCAP" hoverColor="#0B1729" color="#d82655" fontWeight="800" link="https://rccmarketcap.com/currencies/SHROOMZ" />
        </div>
      </Section>
      <Spacer height={"10rem"} />
      <Section style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}>
        <SectionTitle title="Roadmap" style={{ width: "80vw" }} />
        <HorizontalScroll>
          {roadmap.map((step, index) => (
            <Card key={`roadmapcard_${index}`} number={step.step} title={step.title} description={step.subtitle} check={step.done} />
          ))}
        </HorizontalScroll>
      </Section>
      
      <SectionDivider color="#091220" />
      <Section style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#091220",
      }}>
        <Spacer height={"4rem"} />
        <SectionTitle title="Tokenomics" style={{ width: "80vw" }} />
        <Tokenomics />
        <Spacer height={"4rem"} />
        <ProgressBarList list={tokenomics} />
        <Spacer height={"10rem"} />
      </Section>
      <Section style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#091220",
      }}>
        <Spacer height={"4rem"} />
        <SectionTitle title="Explore Stellar Shroomz" style={{ width: "80vw" }} />
        <div className="sectionText">
          <p className="sectionSubtitle">Your SHROOMZ Tokens, Your Control</p>
          <p>StashApp provides a secure and user-friendly interface for storing, sending, and receiving your SLRZ tokens. Whether you're a seasoned investor or new to the crypto space, managing your SLRZ has never been easier.</p>
          <p className="sectionSubtitle">Access Pools and Staking</p>
          <p>Unlock the potential of your SLRZ tokens by participating in liquidity pools and staking programs. Earn rewards while contributing to the growth and stability of the Stellar Shroomz ecosystem.</p>
          <p className="sectionSubtitle">Trade on SDEX</p>
          <p>Gain access to the Stellar Decentralized Exchange (SDEX) through StashApp. Trade SLRZ tokens with ease, explore new investment opportunities, and leverage the liquidity and flexibility of SDEX.</p>
          <Spacer height={"2rem"} />
          <p>Ready to explore the potential of Stellar Shroomz? Join the StashApp community today and start your journey towards financial empowerment.</p>
        </div>
        <Spacer height={"1rem"} />
        <div style={{ display: "flex", width: "50vw", justifyContent: "center", gap: "2rem", flexDirection: "row" }}>
          <Button title="ANDROID" hoverColor="#0B1729" color="#F1CC6F" fontWeight="800" link='https://play.google.com/store/apps/details?id=org.stellar.cannacoin.stashapp.wallet' />
          <Button title="IOS" hoverColor="#0B1729" color="#F1CC6F" fontWeight="800" link='https://apps.apple.com/no/app/stashapp-wallet/id1633871811?l=nb' />
        </div>
        <Spacer height={"4rem"} />
      </Section>
      <Section style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#091220",
      }}>
        <Spacer height={"4rem"} />
        <SectionTitle title={"Token movement"} style={{ width: "80vw" }} />
        { data && <Title title={`$${parseFloat(data?.orderbook?.price).toFixed(7) ?? "0.0000"}`} style={{ width: "80vw", overflow: "hidden" }} /> }
      </Section>
      <BottomHero /> 
    </>
  );
}

export default App;
