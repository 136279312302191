export const useChart = () => {

    let width: number, height: number, gradient: any;
    const getGradient = (ctx: any, chartArea: any) => {
        const chartWidth = chartArea.right - chartArea.left;
        const chartHeight = chartArea.bottom - chartArea.top;
        if (!gradient || width !== chartWidth || height !== chartHeight) {
            // Create the gradient because this is either the first render
            // or the size of the chart has changed
            width = chartWidth;
            height = chartHeight;
            gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
            gradient.addColorStop(1, "#d82655");
            gradient.addColorStop(0, "#F1CC6F");
        }

        return gradient;
    }

    const options = {
        responsive: true,
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    display: false,
                },
                border: {
                    display: false,
                },
            },
            y: {
                grid: {
                    display: false, 
                },
                ticks: {
                    display: false,
                    autoSkip: true,
                    maxTicksLimit: 2,
                    color: "#d82655",
                    font: {
                        size: 26,
                    }
                },
                border: {
                    display: false,
                },
            }
        },
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
            },
            tooltip: {
                enabled: false
            }
        }
    }

    return {
        opertaions: {
            getGradient
        },
        models: {
            options
        }
    }
}
