import "./Card.css";
import { Title } from "../Title";

interface CardProps {
    number: string;
    title: string;
    description: string;
    check?: boolean;
}

const Card = ({
    number,
    title,
    description,
    check
}: CardProps) => {
    return (
        <div className="cardContainer">
            <div className="checkmark">
                {check &&
                    <svg width="30" height="30" viewBox="0 0 101 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M46.1915 0.118179C39.1196 0.844155 33.8317 2.31232 28.0274 5.16115C22.9769 7.64005 19.2536 10.2697 15.1646 14.2463C10.8923 18.4008 7.78818 22.6776 5.16162 28.0274C2.68662 33.0688 1.18467 38.0117 0.354395 43.8477C0.0276367 46.1447 0.0276367 53.8553 0.354395 56.1524C1.18955 62.0233 2.6915 66.959 5.16885 71.9727C10.1407 82.036 17.8833 89.8092 27.8321 94.7264C31.6636 96.6199 33.988 97.4867 37.7931 98.4412C47.4251 100.857 57.1071 100.43 66.6017 97.1699C69.3306 96.233 74.7991 93.5123 77.3438 91.8254C83.2493 87.9111 88.6091 82.4301 92.4604 76.3672C93.8671 74.1526 96.128 69.4801 97.0503 66.8813C98.4591 62.9117 99.4847 58.3133 99.7392 54.8272C99.8079 53.8834 99.9388 53.0652 100.03 53.009C100.121 52.9527 100.195 51.5004 100.195 49.7817C100.195 48.0629 100.12 46.7029 100.029 46.7596C99.9374 46.8162 99.8065 46.0963 99.7382 45.1598C99.3394 39.6955 97.5151 33.3811 94.7081 27.7488C92.0897 22.4955 88.9604 18.2424 84.6378 14.0625C80.5251 10.0856 77.1038 7.69259 71.7774 5.0672C67.1202 2.77169 62.2087 1.24884 56.9571 0.47228C55.0558 0.19103 47.8048 -0.0474462 46.1915 0.118179ZM69.4337 26.7545C69.2188 26.8465 62.9349 33.0371 55.4694 40.5117C48.0038 47.9861 41.8095 54.1016 41.7044 54.1016C41.5991 54.1016 39.0464 51.6406 36.0317 48.6328C31.0021 43.6145 30.496 43.1641 29.8854 43.1641C29.2802 43.1641 28.8437 43.5389 25.0517 47.3145C22.7591 49.5973 20.8331 51.6625 20.772 51.9043C20.7108 52.1461 20.7204 52.5856 20.7931 52.8809C20.9536 53.5342 40.2163 72.9111 41.0472 73.2553C41.3479 73.3799 41.8374 73.4221 42.1351 73.3492C42.745 73.1998 78.5249 37.611 79.0351 36.6463C79.6356 35.5102 79.3849 35.1414 75.1466 30.9272C72.9712 28.7643 70.9995 26.8975 70.7649 26.7785C70.2698 26.5279 69.9755 26.5225 69.4337 26.7545ZM0.0795898 50C0.0795898 51.7725 0.109277 52.4977 0.14541 51.6113C0.181543 50.725 0.181543 49.2748 0.14541 48.3887C0.109277 47.5026 0.0795898 48.2276 0.0795898 50Z" fill="#D82655"/>
                    </svg>
                }
            </div>            
            <Title style={{
                fontSize: "8rem"
            }} title={number} />
            <div className="cardRight">
                <p className="cardTitle">{title}</p>
                <p className="cardText">{description}</p>
            </div>
        </div>
    )
}

export default Card;
