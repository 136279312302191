import React, { createContext, useState, useContext, useEffect } from 'react';
import { config } from 'src/config';
import { queryAssetPriceHistory, queryOrderbook } from 'src/services';

interface AppData {
  orderbook?: any;
  history?: {
    labels: string[];
    data: string[];
  } | any;
  price?: number | null;
}

// Define the shape of your context
interface QueryContextType {
  data: AppData | null;
  loading: boolean;
  error: string | null;
}

// Initialize the context
const QueryContext = createContext<QueryContextType | undefined>(undefined);

// Custom hook to access the context
export const useQueryContext = () => {
  const context = useContext(QueryContext);
  if (!context) {
    throw new Error('useQueryContext must be used within a QueryContextProvider');
  }
  return context;
};

// Context Provider component
export const QueryContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [data, setData] = useState<AppData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    queryOrderbook({ code: config.ASSET_CODE, issuer: config.ASSET_ISSUER })
    .then((response) => {
        setData((prevData) => ({
          ...prevData,
          orderbook: response
        }))
        setLoading(false)
    })
    .catch((error) => {
        setError(error)
        setLoading(false)
    });

    queryAssetPriceHistory({ code: config.ASSET_CODE, issuer: config.ASSET_ISSUER })
    .then((response: number) => {
        setData((prevData) => ({
          ...prevData,
          history: response
        }))
        setLoading(false)
    })


  }, []);

  return (
    <QueryContext.Provider value={{ data, loading, error }}>
      {children}
    </QueryContext.Provider>
  );
};
