import { z } from "zod";
// import "dotenv/config";
// require('dotenv').config();
// import 'dotenv/config' // see https://github.com/motdotla/dotenv#how-do-i-use-dotenv-with-import

export enum NodeEnv {
  PROD = "prd",
  DEV = "dev",
  TEST = "test",
}

const configSchema = z.object({
  NODE_ENV: z.nativeEnum(NodeEnv).default(NodeEnv.PROD),
  ASSET_CODE: z.string(),
  ASSET_ISSUER: z.string(),
});

export const config = configSchema.parse({
    ASSET_CODE: "SHROOMZ",
    ASSET_ISSUER: "GCAKKZCTJPDVMHPSKNRZIAGEJWZS6LQ5NGZIGFN2LIQGC6S2EV5SMHRB",
});

export const appConfig = {
    ASSET_CODE: "SHROOMZ",
    ASSET_ISSUER: "GCAKKZCTJPDVMHPSKNRZIAGEJWZS6LQ5NGZIGFN2LIQGC6S2EV5SMHRB",
}
