import { useState } from "react";
import { IconProps } from "./types";

export const DiscordIcon = ({
    color,
    width,
    height,
    hoverColor
}: IconProps) => {
    const [hover, isHovering] = useState<boolean>(false);

    return (
        <svg 
            onMouseEnter={() => isHovering(true)}
            onMouseLeave={() => isHovering(false)}
            width={width} height={height} viewBox="0 0 92 92" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path 
                fillRule="evenodd" 
                clipRule="evenodd" 
                d="M36.0002 1.37001C13.3752 6.31801 -2.93877 30.047 0.794232 52.578C4.93623 77.572 28.3242 94.862 52.7212 90.965C89.6092 85.072 104.374 40.214 78.0802 13.92C66.6442 2.48401 51.5262 -2.02499 36.0002 1.37001ZM34.0002 24.203C26.3202 26.129 24.9532 27.15 21.8622 33.274C18.5982 39.739 16.0452 49.976 16.0172 56.708C16.0012 60.608 16.3112 61.098 20.2502 63.405C22.5882 64.775 26.1752 66.595 28.2232 67.45L31.9462 69.004L33.4652 66.067C34.8902 63.313 34.8602 63.09 32.9922 62.498C31.8962 62.15 31.0002 61.498 31.0002 61.05C31.0002 60.601 32.9132 60.912 35.2502 61.74C40.8122 63.712 52.4532 63.673 57.2542 61.667C59.3142 60.806 61.0002 60.499 61.0002 60.984C61.0002 61.469 60.1042 62.15 59.0082 62.498C57.1432 63.089 57.1122 63.316 58.5262 66.05L60.0362 68.97L64.2682 67.305C66.5962 66.389 70.1882 64.591 72.2502 63.308C75.6512 61.192 76.0002 60.57 76.0002 56.624C76.0002 45.441 69.9942 29.083 64.9262 26.462C60.1892 24.012 54.1992 23.039 53.5922 24.62C52.8602 26.529 39.4392 26.567 38.3002 24.663C37.7052 23.669 36.6032 23.551 34.0002 24.203ZM31.7762 45.593C29.3942 50.819 35.5022 56.355 39.3752 52.482C41.2802 50.577 41.5192 45.919 39.8002 44.2C37.5732 41.973 33.0692 42.754 31.7762 45.593ZM52.2002 44.2C49.3002 47.1 52.0982 54 56.1742 54C58.1522 54 61.0002 50.817 61.0002 48.606C61.0002 43.787 55.4112 40.989 52.2002 44.2Z"
                fill={hover ? hoverColor : color}
                style={{ transition: "fill 0.3s" }}
            />
        </svg>
        
    )
}
