import { CSSProperties } from "react";
import "./Title.css";

export const Title = ({
    title,
    style
}: {
    title: string;
    style?: CSSProperties
}) => {
    return (
        <div className="titleStroke" style={style}>
            {title}
        </div>
    )
}
